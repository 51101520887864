import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import bold from '@/assets/fonts/Quicksand/Quicksand-Bold.ttf'
import light from '@/assets/fonts/Quicksand/Quicksand-Light.ttf'
import medium from '@/assets/fonts/Quicksand/Quicksand-Medium.ttf'
import regular from '@/assets/fonts/Quicksand/Quicksand-Regular.ttf'
import semiBold from '@/assets/fonts/Quicksand/Quicksand-SemiBold.ttf'
import { ContentCard, MegaTagTitle } from '@/components/Elements'
import { useAppState, useEvents } from '@/features/app/hooks'
import useStyles from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingWizardForm.styles'
import { Layout } from '@/features/booking/components/BookingConfirmed'
import { bookingsAPI } from '@/features/bookings/api'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useLang } from '@/hooks'
import { BookingCheckoutDetails } from './BookingCheckoutDetails'
import CheckoutForm from './CheckoutForm'

export const Checkout = () => {
  const { t } = useTranslation()
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
  )
  const [clientSecret, setClientSecret] = useState(undefined)
  const [paymentId, setPaymentId] = useState(undefined)
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const events = useEvents()

  const { classes } = useStyles()
  const { lang } = useLang()
  const {
    appState: { mobileView },
  } = useAppState()
  const navigate = useNavigate()

  const viewport = useRef<HTMLDivElement>()

  const scrollToTop = () => {
    if (viewport?.current) {
      viewport.current.scrollTo({ top: 0 })
    } else {
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    scrollToTop()
    manageCheckout()
  }, [])

  const manageCheckout = async () => {
    if (!booking || !booking?.id) {
      navigate('/book-now')
    } else {
      const bookingType = booking.type
      events.actions.beforeCheckout()
      if (bookingType == BOOKING_TYPE.ONETIME || bookingType == BOOKING_TYPE.REGULAR) {
        await createPaymentIntent()
      }
    }
  }
  const createPaymentIntent = async () => {
    const { data } = await bookingsAPI.createPaymentIntentV2(booking?.id)
    setClientSecret(data.client_secret)
    setPaymentId(data.id)
  }

  const options: StripeElementsOptions = {
    clientSecret,
    locale: lang.startsWith('pt') ? 'pt' : 'en',
    fonts: [
      {
        family: 'Quicksand',
        src: `url('${light}')`,
        weight: '300',
      },
      {
        family: 'Quicksand',
        src: `url('${regular}')`,
        weight: '400',
      },
      {
        family: 'Quicksand',
        src: `url('${medium}')`,
        weight: '500',
      },
      {
        family: 'Quicksand',
        src: `url('${semiBold}')`,
        weight: '600',
      },
      {
        family: 'Quicksand',
        src: `url('${bold}')`,
        weight: '700',
      },
    ],
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimaryText: '#e26247',
        colorBackground: '#ffffff',
        colorText: '#000000',
        fontSizeBase: mobileView ? '14px' : '16px',
        fontFamily: 'Quicksand',
        fontSizeSm: '16px',
        focusBoxShadow: '0',
      },
      rules: {
        '.Label': {
          fontWeight: 'bold',
          fontFamily: 'Quicksand',
          margin: '8px 0',
        },
        '.Tab--selected': {
          borderColor: '#e26247',
        },
        '.Input': {
          border: '2px solid lightgrey',
          fontSize: mobileView ? '14px' : '16px',
        },
        '.Input:focus': {
          border: '2px solid #e26247',
          outline: 'none',
          boxShadow: '0',
        },
        '.Input--invalid': {
          border: '2px solid #df1b41',
          outline: 'none',
          boxShadow: '0',
        },
        '.AccordionItem': {
          fontSize: '16px',
          padding: '0px !important',
        },
        '.AccordionItem--selected': {
          color: '#e26247',
          padding: '0px !important',
        },
        '.p-AccordionItem.p-AccordionItem': {
          padding: '0px !important',
        },
        '.Error': {
          fontSize: mobileView ? '14px' : '16px',
        },
      },
    },
  }

  return (
    booking && (
      <ContentCard
        title={t('checkout.payment_confirmation')}
        fullHeight={false}
        scrollable={!mobileView}
        viewport={viewport}
        className={classes.cardWrapper}
      >
        <div className={classes.wrapper}>
          <MegaTagTitle title={'booking_confirmed'} />

          <Layout>
            <BookingCheckoutDetails />

            {!!clientSecret && (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm booking={booking} paymentId={paymentId} />
              </Elements>
            )}
          </Layout>
        </div>
      </ContentCard>
    )
  )
}
